<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <CustodyExpenseOperationForm
      v-if="!isLoading"
      :custodyExpenseOperation="custodyExpenseOperation"
      v-on:addCustodyExpenseOperation="addCustodyExpenseOperation()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import CustodyExpenseOperationForm from "@/components/finance/custodyExpenseOperations/CustodyExpenseOperationForm.vue";
import CustodyExpenseOperation from "@/models/finance/custodyExpenseOperations/CustodyExpenseOperation";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustodyExpenseOperationForm,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      custodyExpenseOperation: new CustodyExpenseOperation(),
    };
  },
  methods: {
    async addCustodyExpenseOperation() {
      this.isLoading = true;
      try {
        let response =
          await this.custodyExpenseOperation.addCustodyExpenseOperation(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.custodyExpenseOperation.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
