<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addCustodyExpenseOperation()"
    >
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'custodyToken'"
            :value="custodyExpenseOperation.custodyToken"
            :options="custodyTokenOptions"
            v-on:changeValue="custodyExpenseOperation.custodyToken = $event"
            :title="$t('Custodies.select')"
            :imgName="'Custodies.svg'"
          />

          <div v-if="custodyExpenseOperation.custodyExpenseOperationsData">
            <div
              class="my-card"
              v-for="(
                operation, index
              ) in custodyExpenseOperation.custodyExpenseOperationsData"
              :key="index"
            >
              <span class="my-card-title">{{ index + 1 }}</span>

              <span class="remove-slice-container" v-if="index != 0">
                <button
                  @click="removeSlice(index)"
                  type="button"
                  class="btn btn-remove-slice"
                >
                  ×
                </button>
              </span>

              <div class="row">
                <CustomSelectBox
                  :className="'col-md-4'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseToken]`"
                  :value="operation.custodyExpenseToken"
                  :options="custodyExpenseTokenOptions"
                  v-on:changeValue="operation.custodyExpenseToken = $event"
                  :title="$t('CustodyExpenses.select')"
                  :imgName="'CustodyExpenses.svg'"
                />
                <CustomInputFloat
                  :className="'col-md-4'"
                  :id="`custodyExpenseOperationsData[${index}][moneyPaid]`"
                  :value="operation.moneyPaid"
                  v-on:changeValue="operation.moneyPaid = $event"
                  :title="$t('CustodyExpenseOperations.moneyPaid')"
                  :imgName="'money.svg'"
                />
                <CustomInputFloat
                  :className="'col-md-4'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseOperationQuantity]`"
                  :value="operation.custodyExpenseOperationQuantity"
                  v-on:changeValue="
                    operation.custodyExpenseOperationQuantity = $event
                  "
                  :title="$t('CustodyExpenseOperations.quantity')"
                  :imgName="'number.svg'"
                />

                <TextArea
                  :className="'col-md-6'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseOperationDescriptionAr]`"
                  :value="operation.custodyExpenseOperationDescriptionAr"
                  v-on:changeValue="
                    operation.custodyExpenseOperationDescriptionAr = $event
                  "
                  :title="$t('CustodyExpenseOperations.descriptionAr')"
                  :imgName="'description.svg'"
                />
                <TextArea
                  :className="'col-md-6'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseOperationDescriptionEn]`"
                  :value="operation.custodyExpenseOperationDescriptionEn"
                  v-on:changeValue="
                    operation.custodyExpenseOperationDescriptionEn = $event
                  "
                  :title="$t('CustodyExpenseOperations.descriptionEn')"
                  :imgName="'description.svg'"
                />
                <TextArea
                  :className="'col-md-6'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseOperationDescriptionUnd]`"
                  :value="operation.custodyExpenseOperationDescriptionUnd"
                  v-on:changeValue="
                    operation.custodyExpenseOperationDescriptionUnd = $event
                  "
                  :title="$t('CustodyExpenseOperations.descriptionUnd')"
                  :imgName="'description.svg'"
                />
                <TextArea
                  :className="'col-md-6'"
                  :id="`custodyExpenseOperationsData[${index}][custodyExpenseOperationNotes]`"
                  :value="operation.custodyExpenseOperationNotes"
                  v-on:changeValue="
                    operation.custodyExpenseOperationNotes = $event
                  "
                  :title="$t('notes')"
                  :imgName="'notes.svg'"
                />
              </div>
            </div>

            <div class="add-slice-container">
              <button
                @click="addSlice"
                type="button"
                class="btn btn-add-slice"
                :title="$t('addSlice')"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link
          :to="{ name: 'CustodyExpenseOperations' }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import CustodyExpenseOperationSlice from "@/models/finance/custodyExpenseOperations/CustodyExpenseOperationSlice";
import Custodies from "@/models/finance/custodies/Custodies";
import CustodyExpenses from "@/models/settings/settingsOther/custodyExpenses/CustodyExpenses";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInputFloat,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      custodies: new Custodies(),
      custodyTokenOptions: [],
      custodyExpenses: new CustodyExpenses(),
      custodyExpenseTokenOptions: [],
    };
  },
  props: ["custodyExpenseOperation", "submitName"],
  methods: {
    addSlice() {
      this.custodyExpenseOperation.custodyExpenseOperationsData.push(
        new CustodyExpenseOperationSlice()
      );
    },
    removeSlice(index) {
      this.custodyExpenseOperation.custodyExpenseOperationsData.splice(
        index,
        1
      );
    },
    addCustodyExpenseOperation() {
      this.$emit("addCustodyExpenseOperation");
    },
    async getDialogOfCustodies() {
      this.isLoading = true;
      this.custodyTokenOptions = [];
      this.custodyTokenOptions.push({
        value: "",
        text: this.$t("Custodies.select"),
      });
      try {
        const response = await this.custodies.custody.getDialogOfCustodies(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.custodyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfCustodyExpenses() {
      this.isLoading = true;
      this.custodyExpenseTokenOptions = [];
      this.custodyExpenseTokenOptions.push({
        value: "",
        text: this.$t("CustodyExpenses.select"),
      });
      try {
        const response =
          await this.custodyExpenses.custodyExpense.getDialogOfCustodyExpenses(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.custodyExpenseTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  watch: {},
  created() {
    this.getDialogOfCustodies();
    this.getDialogOfCustodyExpenses();
  },
};
</script>
